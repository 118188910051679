.startup-guide__empty-state-wrapper {
  margin-top: 3.2rem;
}

.startup-guide__mark {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  margin: 0 0.5rem;
  border-radius: 50%;
  background-color: #c4c4c4;

  &--active {
    background-color: #5c5f62;
  }
}
