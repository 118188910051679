.logo-uploader__spinner {
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.logo-uploader__thumbnail-wrapper {
  > span {
    width: unset;
  }
}