.footer {
  width: 100%;
  background-color: #fff;
  padding: 2rem;

  @media (min-width: 768px) {
    padding: 4rem;
  }
}

.footer__wrapper {
  display: grid;
  grid-row-gap: 2rem;
  justify-items: center;

  @media (min-width: 768px) {
    justify-items: start;
    grid-template-columns: max-content repeat(2, 1fr) max-content;
    grid-column-gap: 5rem;
    max-width: 934px;
    margin: auto;
  }
}

.footer__column {
  display: grid;
  grid-row-gap: 1rem;
  justify-items: center;

  @media (min-width: 768px) {
    justify-items: start;
  }
}
