.date-range-picker {
  position: relative;
}

.date-range-picker__date-picker {
  width: 100%;
  position: absolute;
  top: calc(100% + 1.6rem);
  left: 0;
  z-index: 21;
}
