.account-settings__preview {
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  padding: 1.3rem 1.7rem;
  width: 100%;
  margin: 4.2rem 0 0 2rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 20px;
  align-items: center;
}

.account-settings__profile-image-wrapper {
  display: grid;
  height: 100%;
  align-items: center;
}

.account-settings__logo-uploader-wrapper {
  width: 50px;
  height: 50px;
}

.account-setting__input {
  padding: 1.5rem 1.7rem
}
