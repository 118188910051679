$mobile-adaptive: 768px;

.my-retailers__empty-state-wrapper {
  margin-top: 3.2rem;
}

.my-retailers__row {
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  cursor: pointer;

  &--active {
    @media (min-width: $mobile-adaptive) {
      grid-template-columns: auto 30rem 1fr auto;
    }
  }

  &--inactive {
    @media (min-width: $mobile-adaptive) {
      grid-template-columns: auto 1fr auto;
    }
  }
}

.my-retailers__ceil {
  display: grid;
  align-items: center;

  &--active {
    &-second {
      grid-template-rows: repeat(2, 1fr);
    }

    &-third {
      display: block;
      align-self: center;
    }

    &-fourth {
      display: block;
      width: fit-content;
      align-self: center;
    }
  }

  &--inactive {
    &-second {
      grid-template-rows: repeat(2, 1fr);
    }

    &-third {
      display: block;
      width: fit-content;
      align-self: center;
    }
  }
}
