.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: rgb(246, 246, 247);
}
