.my-retailer-details__empty-state-wrapper {
  margin-top: 3.2rem;
}

.my-retailer-details__tabs {
  padding: 2rem 0 1.6rem;
}

.my-retailer-details__pagination-wrapper {
  margin-top: 2rem;
}
