$gap: 0.6rem;
$mobile-adaptive: 860px;

.new-activation__title-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: $gap;
  margin: 2rem 0;

  @media (min-width: $mobile-adaptive) {
    grid-template-columns: 436fr 292fr 190fr;
    grid-column-gap: $gap;
  }
}

.new-activation__time-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: $gap;
}

.new-activation__row {
  display: grid;
  grid-row-gap: $gap;

  @media (min-width: $mobile-adaptive) {
    grid-template-columns: 585fr 310fr;
    grid-column-gap: 2rem;

    &--heading {
      grid-template-columns: 1fr;
    }
  }
}

.new-activation__ceil {
  display: grid;
  align-items: center;

  &--heading {
    grid-template-columns: max-content 1fr;
    grid-column-gap: 2rem;
  }

  &--first {
    grid-template-columns: repeat(2, max-content) 1fr;
    grid-column-gap: 2rem;
  }

  &--second {
    width: fit-content;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8rem;
    justify-items: center;

    @media (min-width: $mobile-adaptive) {
      width: unset;
    }
  }
}

.new-activation__product-title {
  display: grid;
}

.new-activation__text--all-added {
  text-align: center;
}

.new-activation__pagination-wrapper {
  margin-top: 2rem;
}

.new-activation__modal-title {
  margin-bottom: 1.5rem;
}
