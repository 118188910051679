$mobile-adaptive: 981px;

.activations-table__row {
  max-width: 870px;
  display: grid;
  grid-row-gap: 1rem;

  @media (min-width: $mobile-adaptive) {
    grid-template-columns: 333fr 395fr 174fr;
    grid-column-gap: 1rem;
  }
}

.activations-table__ceil {
  display: grid;
  align-self: center;

  &--first {
    grid-template-rows: repeat(2, max-content);
  }

  &--second {
    width: fit-content;
    align-items: center;
    grid-column-gap: 1rem;
    grid-auto-flow: column;
  }

  &--third {
    grid-auto-flow: column;

    @media (min-width: $mobile-adaptive) {
      justify-content: end;
    }
  }
}

.activations-table__title-wrapper {
  width: 100%;
  display: block;

  > h2 {
    display: inline-block;
    word-break: break-word;
    margin-right: 0.5rem;
  }
}
