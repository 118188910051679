.retailers__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr max-content;
  }
}

.retailers__ceil {
  display: grid;

  &--first {
    grid-template-columns: auto 1fr;
    grid-column-gap: 2rem;
    align-items: center;
  }

  &--second {
    justify-items: center;
  }
}
