.get-started {
  width: 100%;
  max-width: 1216px;
  margin: auto;
  min-height: 745px;
  height: 100vh;
  padding-top: 3.2rem;
  background-color: #f6f6f7;
  position: relative;

  @media (min-width: 438px) {
    min-height: 665px;
  }

  @media (min-width: 450px) {
    min-height: 625px;
  }

  @media (min-width: 720px) {
    min-height: 840px;
  }

  @media (min-width: 845px) {
    min-height: 765px;
  }

  @media (min-width: 1005px) {
    min-height: 705px;
  }

  @media (min-width: 1080px) {
    min-height: 665px;
  }
}

.get-started__content {
  max-width: 37rem;
  margin: 0 4rem;
  position: relative;
  z-index: 1;
}

.get-started__title {
  font-weight: 200;
  font-size: 4rem;
  line-height: 4rem;
  margin-top: 1.6rem;
}

.get-started__line {
  width: 100%;
  height: 1px;
  margin: 1.6rem 0;
  background-color: #c25770;
}

.get-started__subtitle {
  font-weight: 400;
  font-size: 2rem;
  line-height: 4rem;
  margin-bottom: 3.3rem;
}

.get-started__button {
  width: fit-content;
  cursor: pointer;
  user-select: none;
  padding: 1.2rem 2.4rem;
  background-color: #c25770;
  border: 1px solid #932b44;
  box-sizing: border-box;
  box-shadow: inset 0rem 0.2rem 0rem rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
}

.get-started__text {
  margin-top: 1.9rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #000;
}

.get-started__image {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  &--mobile {
    max-width: 360px;
  }

  &--desktop {
    display: none;
  }

  @media (min-width: 720px) {
    &--mobile {
      display: none;
    }
  
    &--desktop {
      display: block;
      max-width: 720px;
    }
  }
}

.get-started__modal-text {
  margin: 1rem;
}
