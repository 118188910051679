.onboarding {
  margin-top: 3.2rem;
}

.onboarding__logo-wrapper {
  margin: auto;
  padding: 4.3rem 0 3.3rem;
  width: 100%;
  max-width: 20rem;
}

.onboarding__title-wrapper {
  text-align: center;
}

.onboarding__text {
  text-align: center;
  margin-top: 0.8rem;
  color: #6d7175;
}

.onboarding__button-wrapper {
  width: fit-content;
  margin: auto;
  padding: 3.6rem 0 6.3rem;
}
